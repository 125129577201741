import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ExecutorLeaderFilterComponent } from './executor-leader-filter.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule],
  declarations: [ExecutorLeaderFilterComponent],
  exports: [ExecutorLeaderFilterComponent]
})
export class ExecutorLeaderFilterModule {}
