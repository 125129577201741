/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Order } from '../../core/models/order';
import { TranslatePipe } from '@ngx-translate/core';
import { ToastService } from '../../core/services/toast/toast.service';
import { COLORS } from '../../../constants/colors';
import { OrderType } from '../../core/enums/order-type.enum';
import { InputModalComponent } from '../input-modal/input-modal.component';
import { OrderStatusEnum } from '../../core/enums/order-status.enum';
import { User } from '../../core/models/user';
import { UserRole } from '../../core/enums/user-role.enum';
import { UserService } from '../../core/services/user/user.service';
import { Option } from '../../core/enums/option.enum';

@Component({
  selector: 'app-attachments-modal',
  templateUrl: './orders-modal.component.html',
  styleUrls: ['./orders-modal.component.scss']
})
export class OrdersModalComponent implements OnInit {
  @Input() user: User;
  @Input() orders: Order[];
  @Input() orderType = OrderType.delivery;
  @Input() requireReturnFieldInput = false;
  @Input() returnFields: string[];
  @Input() ordersUpdater?: EventEmitter<Order[]>;
  @Input() refreshOrders?: EventEmitter<void>;

  public isEdited: boolean;
  public OrderType = OrderType;
  public UserRole = UserRole;
  public OrderStatusEnum = OrderStatusEnum;
  public showArticleIdInsteadofDesc = false;

  public saveButtonDisabled = false;

  private orderModalReference: HTMLIonModalElement;

  constructor(
    private modalController: ModalController,
    private translatePipe: TranslatePipe,
    private alertController: AlertController,
    private toastService: ToastService,
    private userService: UserService
  ) {}

  ngOnInit() {
    // Get modal ref
    this.modalController.getTop().then((modalRef) => {
      this.orderModalReference = modalRef;
    });

    // Check if user has option to show article id instead of description
    this.showArticleIdInsteadofDesc = this.userService.userHasOption(this.user, Option.orderArtdesc);

    // Refresh orders
    this.refresh();
  }

  public dismiss(): void {
    this.modalController.dismiss();
  }

  public async onChange(order: Order): Promise<void> {
    this.isEdited = true;

    if (this.orderType === OrderType.delivery) {
      // Check if value is valid
      if (order.delivered < 0) {
        // Disable save button
        this.saveButtonDisabled = true;
        // Show error toast message
        this.toastService.showToastMessage(this.translatePipe.transform('ordersModal.inputToLow'), COLORS.danger);
        // Stop method here
        return;
      } else {
        this.saveButtonDisabled = false;
      }
    } else {
      // Reset shortage
      order.hasShortage = false;
      // Check if there is a difference between rented and returned
      if (order.returned !== order.rented) {
        if (order.returned < 0 || order.returned > order.rented) {
          // Disable save button
          this.saveButtonDisabled = true;
          // Show error toast message
          this.toastService.showToastMessage(this.translatePipe.transform('ordersModal.inputToHighOrToLow'), COLORS.danger);
          // Stop method here
          return;
        }
        // Set shortage
        order.hasShortage = order.returned < order.rented && order.returned > -1;
      }
      // Enable save button
      this.saveButtonDisabled = false;
    }
  }

  public refresh(event?: any): void {
    // Subscribe on orders updates
    const sub = this.ordersUpdater.subscribe((orders) => {
      // Unsubscribe
      sub.unsubscribe();
      // Set Orders
      this.orders = orders;
      if (event) {
        // Stop refresher icon
        event.target.complete();
      }
    });
    // Ask for update
    this.refreshOrders.emit();
  }

  public async save(): Promise<void> {
    // Ask for confirmation
    this.confirmSave().then(async (confirmed) => {
      if (confirmed) {
        if (this.requireReturnFieldInput) {
          const modal = await this.presentReturnFieldInputModal();
          const { data } = await modal.onDidDismiss();
          // Check if there is input data
          if (data) {
            if (data.input.length > 0) {
              // Add return field to every order
              for (const order of this.orders) {
                order.returnField = data.input;
              }
            }
          }
        }

        // Check if at least one order has shortage
        const receiptOutOfBalance = this.orders.some((order) => order.rented > 0 && order.rented - order.returned !== 0);

        // Check if receipt is out of balance
        if (receiptOutOfBalance) {
          const alert = await this.alertController.create({
            backdropDismiss: false,
            header: this.translatePipe.transform('ordersModal.shortageAlertTitle'),
            message: this.translatePipe.transform('ordersModal.shortageAlertMessage'),
            buttons: [
              {
                text: this.translatePipe.transform('ordersModal.shortageAlertReRentButton'),
                role: 'cancel',
                handler: () => {
                  for (const order of this.orders) {
                    // Edit orders
                    order.hasShortage = false;
                    order.shortage = 0;
                    // Send changes to parent
                    this.orderModalReference.dismiss(this.orders);
                  }
                }
              },
              {
                text: this.translatePipe.transform('ordersModal.shortageAlertShortageButton'),
                handler: () => {
                  for (const order of this.orders) {
                    // Edit orders
                    order.hasShortage = true;
                    order.shortage = order.rented - order.returned;
                    // Send changes to parent
                    this.orderModalReference.dismiss(this.orders);
                  }
                }
              }
            ]
          });
          await alert.present();
        } else {
          // Send changes to parent
          this.orderModalReference.dismiss(this.orders);
        }
      }
    });
  }

  /**
   * Ask user to confirm changes and return true or false
   */
  public async confirmSave(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      // Make sure no empty input fields are submitted
      if (this.everyRowHasValue()) {
        // Show confirmation alert
        const confirmationAlert = await this.alertController.create({
          header: this.translatePipe.transform('ordersModal.confirmHeader'),
          message: this.translatePipe.transform('ordersModal.confirmMessage'),
          buttons: [
            {
              text: this.translatePipe.transform('ordersModal.cancelButton'),
              role: 'cancel',
              handler: () => {
                resolve(false);
              }
            },
            {
              text: this.translatePipe.transform('ordersModal.okButton'),
              handler: () => {
                resolve(true);
              }
            }
          ]
        });

        await confirmationAlert.present();
      } else {
        // Toast message that every row needs a value
        await this.toastService.showToastMessage(this.translatePipe.transform('ordersModal.setValueInEveryRow'), COLORS.danger);
      }
    });
  }

  public async presentReturnFieldInputModal(): Promise<HTMLIonModalElement> {
    // Create modal
    const modal = await this.modalController.create({
      component: InputModalComponent,
      cssClass: 'input-modal',
      componentProps: {
        question: this.translatePipe.transform('ordersModal.returnFieldQuestion'),
        inputType: 'string',
        inputLabel: this.translatePipe.transform('ordersModal.returnFieldLabel'),
        cancelButtonText: this.translatePipe.transform('ordersModal.returnFieldCancel'),
        confirmButtonText: this.translatePipe.transform('ordersModal.returnFieldConfirm')
      }
    });
    // Present modal
    await modal.present();
    // Return modal reference
    return modal;
  }

  public hasWharfOption(): boolean {
    return this.userService.userHasOption(this.user, Option.wharf);
  }

  /**
   * Returns true if every delivery (for delivery orders) or return (for return orders) has a value.
   */
  private everyRowHasValue(): boolean {
    // Loop through orders
    for (const order of this.orders) {
      // Check if type is delivery
      if (this.orderType === OrderType.delivery) {
        // Check if every delivery has a value
        if (order.delivered === undefined || order.delivered === null) {
          return false;
        }
        // Check if type is return
      } else if (this.orderType === OrderType.return) {
        // Check if every return has a value
        if (order.returned === undefined) {
          return false;
        }
      }
    }
    return true;
  }
}
