import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-executor-leader-filter',
  templateUrl: './executor-leader-filter.component.html',
  styleUrls: ['./executor-leader-filter.component.scss']
})
export class ExecutorLeaderFilterComponent implements OnInit {
  @Output() filterChanged = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}
}
