import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Question } from 'src/app/core/models/checklist/question';
import { ChecklistTypeEnum } from '../../../../core/enums/checklist-type.enum';
import { SignatureComponent } from '../../../signature/signature.component';

@Component({
  selector: 'app-checklist-question',
  templateUrl: './checklist-question.component.html',
  styleUrls: ['./checklist-question.component.scss']
})
export class ChecklistQuestionComponent implements OnInit {
  @Input() checklistType: ChecklistTypeEnum;

  // Unanswered question as input
  @Input() question: Question;

  // Answered question as output
  @Output() answerChanged = new EventEmitter<Question>();

  // Constants
  public maxTextAreaLength = 350;
  public checklistTypes = ChecklistTypeEnum;

  public signatureSet = false;

  constructor() {}

  ngOnInit() {}

  /**
   * Converts givens answers to string in antwoord property
   */
  public onRadioClick(choice: string): void {
    switch (choice) {
      case 'true':
        this.question.answer = 'true';
        this.answerChanged.emit(this.question);
        break;
      case 'false':
        this.question.answer = 'false';
        this.answerChanged.emit(this.question);
        break;
      default:
        this.question.answer = 'undefined';
        this.answerChanged.emit(this.question);
        break;
    }
  }

  /**
   * Adds input to answer property
   * @param input Input string
   */
  public onTextAreaChange(input: string) {
    if (input) {
      this.question.answer = input.slice(0, this.maxTextAreaLength);
    }
    this.answerChanged.emit(this.question);
  }

  public onSignatureChange(signature: SignatureComponent): void {
    const signatureAsBase64: string = signature.getSignature();
    this.question.answer = signatureAsBase64;
    this.answerChanged.emit(this.question);
  }
}
