import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { getAppInfo } from '../../core/utils';
import { IsAppInfo } from '../../core/interfaces/is-app-info';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() pageTitle: string;
  @Input() showBackButton: boolean;
  @Input() goToUrl?: string;

  public appInfo: IsAppInfo;

  public develop = !environment.production;

  constructor(private location: Location, private router: Router) {}

  ngOnInit() {
    this.appInfo = getAppInfo();
  }

  public back(): void {
    if (this.goToUrl) {
      this.router.navigate([this.goToUrl]);
    } else {
      this.location.back();
    }
  }
}
