import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.scss']
})
export class InputModalComponent implements OnInit {
  @Input() question: string;
  @Input() inputType: 'string' | 'number' | 'password';
  @Input() inputLabel: string;
  @Input() input = '';
  @Input() cancelButtonText: string;
  @Input() confirmButtonText: string;

  constructor(public modalController: ModalController) {}

  ngOnInit() {}
}
