import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController, Platform } from '@ionic/angular';
import { TranslatePipe } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Checklist } from 'src/app/core/models/checklist/checklist';
import { Question } from 'src/app/core/models/checklist/question';
import { ChecklistTypeEnum } from '../../core/enums/checklist-type.enum';

@Component({
  selector: 'app-checklist-modal',
  templateUrl: './checklist-modal.component.html',
  styleUrls: ['./checklist-modal.component.scss']
})
export class ChecklistModalComponent implements OnInit, AfterViewInit {
  @ViewChild(IonSlides) sliderView;

  // Input data
  @Input() checklist: Checklist;

  // Slider options
  public slideOpts = {
    initialSlide: 0,
    observer: true,
    observeParents: true,
    speed: 400,
    allowTouchMove: false
  };
  // info booleans
  public isNative: boolean;

  private nextQuestionTimeOut = 250;

  private slidesReady = true;

  // References
  private modal: HTMLIonModalElement;
  private slider: any;
  private backButtonSubscription: Subscription;

  constructor(private modalController: ModalController, private platform: Platform, private translatePipe: TranslatePipe) {}

  ngOnInit() {
    console.log(this.checklist);
    // Run onDismiss when Android back button is pressed
    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(5, () => {
      this.onDismiss();
    });
  }

  async ngAfterViewInit(): Promise<void> {
    // Get slider reference
    this.slider = await this.sliderView.getSwiper();
    // Got to the last used slide
    if (this.checklist.currentIndex !== 0) {
      this.changeToSlide(this.checklist.currentIndex);
    }

    // Get this model reference
    this.modalController.getTop().then((modal) => {
      this.modal = modal;
    });
  }

  /**
   * Changes to the slide with the given index.
   * @param slideIndex Index of question on slide
   */
  public changeToSlide(slideIndex: number) {
    if (this.slidesReady) {
      this.slidesReady = false;
      this.checklist.currentIndex = slideIndex;
      this.slider.slideTo(slideIndex);
      // Wait for slide to finish before processing new request
      setTimeout(() => {
        this.slidesReady = true;
      }, this.slideOpts.speed);
    }
  }

  /**
   * Close modal when cross is pressed.
   */
  public async onDismiss() {
    this.backButtonSubscription.unsubscribe();
    this.modal.dismiss();
  }

  public onAnswerChange(answeredQuestion: Question) {
    switch (answeredQuestion.type) {
      // If answered question type is dichotomous
      case 'dichotomous':
        // Add a note question if dichotomous answer is unanswered
        if (answeredQuestion.answer === 'false') {
          // Clone the answered question
          const noteQuestion = { ...answeredQuestion };
          // Delete given answer
          noteQuestion.answer = '';
          // Add note question based on checklist type
          switch (this.checklist.type) {
            case ChecklistTypeEnum.yesNo:
              noteQuestion.question = this.translatePipe.transform('checklistModal.whyNo');
              break;
            case ChecklistTypeEnum.goodBad:
              noteQuestion.question = this.translatePipe.transform('checklistModal.whyBad');
              break;
          }
          // Set note type
          noteQuestion.type = 'note';
          // Insert note after current answered question
          this.checklist.questions.splice(this.checklist.currentIndex + 1, 0, noteQuestion);
        } else if (answeredQuestion.answer !== 'False' && this.checklist.questions[this.checklist.currentIndex + 1].type === 'note') {
          // Delete note question answer if answered question is !== 'False'
          answeredQuestion.note = '';
          // Delete sheet
          this.checklist.questions.splice(this.checklist.currentIndex + 1, 1);
        }
        // Go to next sheet automatically
        setTimeout(() => this.changeToSlide(this.checklist.currentIndex + 1), this.nextQuestionTimeOut);
        break;
      // If answered question type is note
      case 'note':
        // Add note answer to question before this one
        this.checklist.questions[this.checklist.currentIndex - 1].note = answeredQuestion.answer;
        break;
    }
  }

  /**
   * Marks checklist as completed and closes modal.
   */
  public checklistCompleted(): void {
    this.checklist.completed = true;
    this.checklist.questions = this.convertToBrainsAnswers(this.checklist.questions);
    this.modalController.dismiss({
      checklist: this.filterOutNoteQuestions(this.checklist)
    });
  }

  private filterOutNoteQuestions(checklist: Checklist): Checklist {
    checklist.questions = checklist.questions.filter((question) => question.type !== 'note');
    return checklist;
  }

  private convertToBrainsAnswers(questions: Question[]): Question[] {
    const convertedQuestions = [...questions];

    // Convert answer to the brains format based on checklist type
    for (const convertedQuestion of convertedQuestions) {
      if (convertedQuestion.type === 'dichotomous') {
        switch (convertedQuestion.answer) {
          case 'true':
            switch (this.checklist.type) {
              case ChecklistTypeEnum.yesNo:
                convertedQuestion.answer = 'Ja';
                break;
              case ChecklistTypeEnum.goodBad:
                convertedQuestion.answer = 'Goed';
                break;
            }
            break;
          case 'false':
            switch (this.checklist.type) {
              case ChecklistTypeEnum.yesNo:
                convertedQuestion.answer = 'Nee';
                break;
              case ChecklistTypeEnum.goodBad:
                convertedQuestion.answer = 'Slecht';
                break;
            }
            break;
          default:
            convertedQuestion.answer = 'N.v.t.';
            break;
        }
      }
    }

    return convertedQuestions;
  }
}
