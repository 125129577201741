import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import interact from 'interactjs';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit, AfterViewInit {
  public signatureHasChanged = false;
  private interactionDelay = 500; // To prevent canvas from being undefined
  private backgroundColor = 'white';

  @Output() signatureChanged = new EventEmitter<boolean>();

  // View references
  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef<HTMLCanvasElement>;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.interactWithDrawingCanvas();
    }, this.interactionDelay);
  }

  public signatureTouched(): void {
    this.signatureHasChanged = true;
    this.signatureChanged.emit(this.signatureHasChanged);
  }

  public getSignature(): string {
    return this.signatureCanvas.nativeElement.toDataURL();
  }

  private interactWithDrawingCanvas(): void {
    // Set initial parameters
    const pixelSize = 2;
    const lineWidth = 5;
    // Enable interactive drawing on drawing-canvas
    interact('#signature-canvas')
      .draggable({
        max: Infinity,
        maxPerElement: Infinity,
        origin: 'self',
        modifiers: [
          // Enable snapping to a grid
          interact.modifiers.snap({
            targets: [interact.snappers.grid({ x: pixelSize, y: pixelSize })]
          })
        ],
        listeners: {
          // Handle the move event for drawing
          move: (event) => {
            const context = event.target.getContext('2d');

            // Set the line styles
            context.lineWidth = lineWidth;
            context.lineCap = 'round';
            context.lineJoin = 'round';
            context.strokeStyle = '#000000';

            // Calculate control points for a Bezier curve
            const previousX = event.pageX - event.dx;
            const previousY = event.pageY - event.dy;
            const cp1x = (previousX + event.pageX) / 2;
            const cp1y = (previousY + event.pageY) / 2;
            const cp2x = (cp1x + event.pageX) / 2;
            const cp2y = (cp1y + event.pageY) / 2;

            // Draw a Bezier curve
            context.beginPath();
            context.moveTo(previousX, previousY);
            context.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, event.pageX, event.pageY);
            context.stroke();
            context.closePath();
          }
        }
      })
      .on('doubletap', (event) => {
        const context = event.target.getContext('2d');

        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        this.resizeDrawingCanvas();
      });

    setTimeout(() => {
      // Call the resize function to initialize canvas sizes
      this.resizeDrawingCanvas();
    }, this.interactionDelay);
  }

  /**
   * Let canvas resize to fit screen
   */
  private resizeDrawingCanvas(): void {
    // Get canvas element
    const canvas = document.getElementById('signature-canvas') as HTMLCanvasElement;

    // Remove width and height attributes
    delete canvas.width;
    delete canvas.height;

    // Get the bounding rectangle of the canvas
    const rect = canvas.getBoundingClientRect();

    // Set canvas dimensions based on the rectangle
    canvas.width = rect.width;
    canvas.height = rect.height;

    // Set background color
    const context = canvas.getContext('2d');
    context.fillStyle = this.backgroundColor;
    context.fillRect(0, 0, this.signatureCanvas.nativeElement.width, this.signatureCanvas.nativeElement.height);
  }
}
